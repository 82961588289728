import Vue from 'vue/dist/vue.esm';
import { ApolloProvider } from '../apollo.js';
import { DefaultMixins } from '../default.js'
import PermissionPlugin from 'components/global/permission_plugin';

// Store
import store from 'store/shared/store.js';
Vue.use(PermissionPlugin, { store });

// Components
import { i18n } from 'locales/shared/locale.js';

Vue.config.productionTip = false;

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    mixins: DefaultMixins,
    store,
    el: '.main-body',
    i18n,
    provide: ApolloProvider.provide()
  });
});
