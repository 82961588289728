import Vue from 'vue/dist/vue.esm';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, from } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import VueApollo from 'vue-apollo';

const httpLink = new HttpLink({
  uri: gon.vue_graphql
});

// Function acts as a request-response interceptor for Loader Toggling
// 'operation.variables.hideLoader' is a setting to control loader setting per component
// forward() => returns a response observable

const toggleLoader = new ApolloLink((operation, forward) => {
  var body = $('body');
  body.trigger('ajaxSend');
  window.dispatchEvent(new CustomEvent('ajaxRequestStart'));

  return forward(operation).map(response => {
    window.setTimeout(() => {
      $('body').trigger('ajaxComplete');
      window.dispatchEvent(new CustomEvent('ajaxRequestComplete'));
    }, 5)
    return response;
  });
});

// const finalLink = ApolloLink.concat(toggleLoader, httpLink);

const apolloClient = new ApolloClient({
  link: from([toggleLoader, httpLink]),
  cache: new InMemoryCache(),
  connectToDevTools: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only'
    },
    query: {
      fetchPolicy: 'network-only'
    }
  }
});

Vue.use(VueApollo);

export const ApolloProvider = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    $loadingKey: 'loading'
  }
});

