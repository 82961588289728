const PermissionPlugin = {
  install(Vue, options) {
    Vue.mixin({
      computed: {
        gsp: function() {
          return options.store.state.Permission.permissions;
        },
        dsp: function() {
          return options.store.state.Permission.dynamicPermissionsProxy;
        }
      }
    });
  }
};

export default PermissionPlugin;
